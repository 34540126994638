$(document).ready(function () {
  $('.burger-icon').click(function () {
    $('.mobile-menu').toggleClass('active');
  });
  $('.language-changer').click(function () {
    $('.ru').toggleClass('active');
  });
  $('.owl-carousel').owlCarousel({
    items: 1,
    margin: 0,
    autoplay: true,
    drag: false,
    mouseDrag: false,
    animateOut: 'fadeOut',
    loop: true,
    autoplayTimeout: 4000
  });
});


$(window).scroll(function () {
  var scroll = $(window).scrollTop();
  //console.log(scroll);
  if (scroll >= 25) {
    //console.log('a');
    $('.left-fixed-label').addClass('active');
  }
});

